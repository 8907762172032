.sneakpeaks {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 200px 0 200px 0;
  width: 100%;
  background-image: url(./meteoros-v2.gif);
  background-size: cover;
}
.about-us {
  font-size: 80px;
  font-family: PixelH;
  color: green;
}
.left-half {
  text-align: left;
  width: 40%;
  color: white;
  font-family: Pixel;
  font-size: 23px;
  letter-spacing: 2px;
}
.right-half {
  position: relative;
}
.main__sneak {
  width: 200px;
  height: 200px;
  position: relative;
  /* visibility: hidden; */
}
.main__sneak1 {
  position: absolute;
  width: 150px;
  height: 150px;
  bottom: 220px;
  right: 130px;
}
.main__sneak2 {
  position: absolute;
  width: 150px;
  height: 150px;
  bottom: 220px;
  left: 130px;
}
.main__sneak3 {
  position: absolute;
  width: 150px;
  height: 150px;
  left: 245px;
  top: 20px;
}
.main__sneak4 {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 220px;
  left: 130px;
}
.main__sneak5 {
  position: absolute;
  width: 150px;
  height: 150px;
  top: 220px;
  right: 130px;
}
.main__sneak6 {
  position: absolute;
  width: 150px;
  height: 150px;
  right: 245px;
  top: 20px;
}
.right-half div img:hover {
  transform: scale(1.2);
  transform: 0.5s;
  cursor: pointer;
}

.right-half div img {
  border-radius: 50%;
}
@media screen and (max-width: 1200px) {
  .sneakpeaks {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0px 0 200px 0;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    flex-direction: column;
  }
  .about-us {
    font-size: 50px;
    font-family: Pixel;
    color: green;
  }
  .left-half {
    text-align: center;
    width: 90%;
    color: white;
    font-family: Pixel;
    font-size: 20px;
    letter-spacing: 2px;
  }
  .right-half {
    position: relative;
    margin-top: 130px;
  }
  .main__sneak {
    width: 100px;
    height: 100px;
    position: relative;
    /* visibility: hidden; */
  }
  .main__sneak1 {
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 120px;
    right: 80px;
  }
  .main__sneak2 {
    position: absolute;
    width: 70px;
    height: 70px;
    bottom: 120px;
    left: 80px;
  }
  .main__sneak3 {
    position: absolute;
    width: 70px;
    height: 70px;
    left: 145px;
    top: 20px;
  }
  .main__sneak4 {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 120px;
    left: 80px;
  }
  .main__sneak5 {
    position: absolute;
    width: 70px;
    height: 70px;
    top: 120px;
    right: 80px;
  }
  .main__sneak6 {
    position: absolute;
    width: 70px;
    height: 70px;
    right: 145px;
    top: 20px;
  }
}
@media screen and (max-width: 350px) {
  .main__sneak {
    width: 100px;
    height: 100px;
    position: relative;
    /* visibility: hidden; */
  }
  .main__sneak1 {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 120px;
    right: 80px;
  }
  .main__sneak2 {
    position: absolute;
    width: 50px;
    height: 50px;
    bottom: 120px;
    left: 80px;
  }
  .main__sneak3 {
    position: absolute;
    width: 50px;
    height: 50px;
    left: 145px;
    top: 20px;
  }
  .main__sneak4 {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 120px;
    left: 80px;
  }
  .main__sneak5 {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 120px;
    right: 80px;
  }
  .main__sneak6 {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 145px;
    top: 20px;
  }
}
