.roadmap {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background: black;
  background-size: cover;
  /* border: 2px white solid; */
  padding: 50px 0 50px 0;
}
/* .roadmap-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  background-image: url(./meteoros-v2.gif);
  background-size: cover;
  border: 2px white solid;
  padding: 50px 0 50px 0;
} */
.splash1 {
  width: 100px;
  height: auto;
  top: 0%;
  left: 0%;
  position: absolute;
}
.splash2 {
  width: 100px;
  height: auto;
  top: 50%;
  right: 0%;
  position: absolute;
}
.splash3 {
  width: 100px;
  height: auto;
  bottom: 0%;
  left: 0%;
  position: absolute;
}
.roadmap-title {
  font-size: 80px;
  font-family: PixelH;
  color: green;
}
.phase1 {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row;
  width: 800px;
  padding: 50px;
}
/* .phasesimg {
  width: 40% !important;
}
.phase-text {
  width: 60% !important;
} */

.phase-text {
  position: relative;
  width: 50%;
  font-family: Pixel;
  font-size: 22px;
  text-align: left;
  color: white;
}
.phase1-title {
  font-family: PixelH;
  font-size: 40px;
  color: green;
  text-transform: uppercase;
}
.phasesimg {
}
.phasesimg img {
  width: 300px;

  height: auto;
}
hr {
  width: 100%;
}
@media screen and (max-width: 1200px) {
  .roadmap {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;
    text-align: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.089);
    /* border: 2px white solid; */
    padding: 20px 0 20px 0;
  }
  .splash1 {
    width: 50px;
    height: auto;
    top: 0%;
    left: 0%;
    position: absolute;
  }
  .splash2 {
    width: 50px;
    height: auto;
    top: 50%;
    right: 0%;
    position: absolute;
  }
  .splash3 {
    display: none;
  }
  .roadmap-title {
    font-size: 50px;
    color: green;
  }
  .phase1 {
    display: flex;
    justify-content: center !important;
    align-items: center;
    flex-direction: column;

    padding: 0 !important;
    width: 100%;
    text-align: center;
  }
  .inverted {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse !important ;
  }
  .phase-text {
    position: relative;
    width: 80%;
    font-family: Pixel;
    font-size: 15px;
    text-align: left;
    color: white;
    letter-spacing: 2px;
  }
  .phase1-title {
    font-family: Pixel;
    font-size: 30px;
    color: green;
  }
  .phasesimg {
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}
