.landing-video {
  height: 100vh;
  width: 100%;
}
.buttons {
  position: relative;
}
.landing-button {
  position: relative;
}
.button-text {
  font-family: Pixel;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}
.button-text img {
  width: 40px;
  height: auto;
}
.landing-button-body {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 500px;
  left: 50%;

  transform: translateX(-50%);
}
.middle-section1 {
}
@media screen and (max-width: 1200px) {
  .landing-video {
    height: auto;
    width: 100%;
  }
  .button-text {
    font-family: Pixel;
    font-size: 15px;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 15px;
    color: white;
  }

  .landing-button-body {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    width: auto;
    height: 100px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
  }
  .landing-button {
    position: relative;
    width: 120px;
    height: auto;
  }
  .button-text img {
    width: 25px;
    height: auto;
  }
}
