.faq {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 50px 0 100px 0;
}
.faq-body {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  flex-direction: row !important;
  width: 95%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  padding: 50px 0 50px 0;
}
.faq-title {
  font-size: 80px;
  color: green;
  font-family: PixelH;
}
.blink {
  animation: blink 0.5s linear infinite alternate 0.5s;
}

@keyframes blink {
  80% {
    color: green;
  }
  90% {
    color: rgb(255, 255, 255);
  }
  100% {
    color: green;
  }
}
.faq-left {
  width: 50%;
  text-align: left;
}
.faq-right {
  width: 50%;
}
.question {
  color: green;
  padding: 20px;
  margin-top: 0;
  cursor: pointer;
  font-family: PixelH;
  font-size: 30px;
  margin-top: 5px;
}
.question div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.answer {
  color: white;
  margin-left: 10px;
  margin-top: 15px;
  /* transition: 0.5s; */
  font-family: Pixel;
  font-size: 25px;
  height: auto;
}
.answer-hidden {
  visibility: hidden;
  height: 10px;
  /* transition: 0.5s; */
}
.faq-img {
  width: 500px;
  height: auto;
}
.question:active {
  border: 2px white solid !important;
  transition: 0.8s;
}
@media screen and (max-width: 1200px) {
  .faq {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 100px 0;
  }
  .faq-body {
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    flex-direction: column !important;
    width: 100%;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: 50px 0 50px 0;
  }
  .faq-title {
    font-size: 50px;
    color: green;
    font-family: Pixel;
  }
  .faq-left {
    width: 100%;
    text-align: left;
  }
  .faq-right {
    width: 100%;
  }
  .question {
    color: green;
    padding: 20px;
    margin-top: 0;
    cursor: pointer;
    font-family: Pixel;
    font-size: 30px;
    margin-top: 5px;
  }
  .question div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .answer {
    color: white;
    margin-left: 10px;
    transition: 0.5s;
    font-family: Pixel;
    font-size: 28px;
  }
  .answer-hidden {
    visibility: hidden;
    /* transition: 0.5s; */
  }
  .faq-img {
    display: none;
  }
  .question:active {
    border: 2px white solid !important;
    transition: 0.8s;
  }
}
