body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  width: 6px; /* width of the entire scrollbar */
}

body::-webkit-scrollbar-track {
  background: #02080d; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: green; /* color of the scroll thumb */
  border-radius: 10px; /* roundness of the scroll thumb */
}
