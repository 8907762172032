.teams-body {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 50px;
}
.teams {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 100px 0 100px 0;
}
.teams-title {
  font-size: 80px;
  color: green;
  font-family: PixelH;
}
.teams-img {
  width: 300px;
  height: auto;
}
.teams-body div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.teams-name {
  font-size: 35px;
  color: rgb(255, 255, 255);
  font-family: PixelH;
  margin-top: 20px;
}
.teams-desig {
  font-size: 20px;
  color: rgb(255, 255, 255);
  font-family: Pixel;
  margin-top: 10px;
}
@media screen and (max-width: 1200px) {
  .teams-body {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    flex-direction: column;
    width: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 50px;
  }
  .teams {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 100px 0 100px 0;
  }
  .teams-title {
    font-size: 80px;
    color: green;
    font-family: Pixel;
  }
  .teams-img {
    width: 200px;
    height: auto;
  }
  .teams-body div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .teams-name {
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-family: Pixel;
    margin-top: 20px;
  }
  .teams-desig {
    font-size: 30px;
    color: rgb(255, 255, 255);
    font-family: Pixel;
    margin-bottom: 50px;
  }
}
