.join-community {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgb(17, 17, 17);
  /* margin: 100px 0 100px 0; */
  text-align: center;

  position: relative;
}
.join-body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* background-color: rgb(17, 17, 17); */
  /* margin: 100px 0 100px 0; */
  text-align: center;

  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bkg-join {
  position: relative;
  width: 100%;
  opacity: 0.8;
}
.join {
  color: rgb(255, 255, 255);
  font-size: 80px;
  font-family: PixelH;
}
.dino {
  font-size: 30px;
  color: white;
  font-family: PixelH;
  margin: 20px 0 20px 0;
}
.rights {
  color: white;
  font-family: PixelH;
  font-size: 20px;
}
.join-discord {
  margin-left: 10px;
  margin-right: 10px;
}
@media screen and (max-width: 1200px) {
  .bkg-join,
  .join-community {
    min-height: 300px;
  }
  .join {
    color: rgb(255, 255, 255);
    font-size: 30px;
    font-family: PixelH;
  }
  .join-discord {
    width: 30px;
    height: auto;
  }
  .dino {
    font-size: 20px;
    color: white;
    margin: 10px 0 10px 0;
    font-family: PixelH;
  }
  .rights {
    color: white;
    font-family: PixelH;
    font-size: 14px;
  }
}
